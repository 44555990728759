<template>
    <div class="p-2">
        <div>
            <div class="text-center">
                <h3>Well done for completing {{ title }}</h3>
            </div>
            <div class="mt-3 w-600">
                <p class="font-weight-normal text-center">Summary</p>

                <app-collapse accordion type="margin">
                    <app-collapse-item v-for="(worksheet, index) in currentToolkit.worksheets" :key="index" :title="worksheet.text_1" :worksheet="worksheet">
                        <small v-if="worksheet.text_2">{{ worksheet.text_2 }}</small>
                    </app-collapse-item>
                </app-collapse>

            </div>
        </div>
        <div class="mt-5 text-center">
          <div class="w-500 mx-auto mt-3">
            <label>Reminder</label>
                <b-form-select v-model="selectedReminder" :options="reminderOptions"/>
          </div>
        </div>
    </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton, VBTooltip, BFormSelect
} from 'bootstrap-vue'

export default {
    props: {
        title: {
            type: String,
            default: 'toolkit',
        },
    },
    components: {
        BButton,
        AppCollapse,
        BFormSelect,
        AppCollapseItem
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            summary: {},
            selectedReminder: "weekly",
            reminderOptions: [
              { value: "weekly", text: "Weekly"},
              { value: "monthly", text: "Monthly"},
              { value: "quarterly", text: "Quarterly"},
              { value: "never", text: "Never"},
            ]
        }
    },
    computed: {
        currentToolkit() {
            return this.$store.getters["toolkitStoreModule/currentToolkit"]
        },
    },
}
</script>

<style scoped>
    .w-600 {
        max-width: 600px;
        margin: auto;
    }
    .w-500 {
        max-width: 500px;
    }
</style>         