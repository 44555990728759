<template>
    <div>
        <b-card v-if="currentToolkit.worksheets != undefined">
            <div v-if="currentToolkit.worksheets[worksheetIndex] != undefined">
                <b-row class="justify-content-center">
                    <h4>{{currentToolkit.title}}</h4>
                </b-row>

                <b-row class="no-gutters mt-05" v-if="currentToolkit.professional != null">
                    <div class="d-flex justify-center items-center mx-auto cursor-pointer" @click="openProfessionalProfileModal()">
                        <img src="/verified.png" class="mr-05" /> 
                        <small class="text-gray">Reviewed by {{currentToolkit.professional.first_name}} {{currentToolkit.professional.last_name}}</small>
                    </div>
                    
                    <professional-profile :professional="currentToolkit.professional" />
                </b-row>

                <b-row
                    class="justify-content-between no-gutters m-auto mb-2"
                    style="width: 90%"
                >
                    <div class="w-100 mt-2">
                        <b-progress
                            :value="toolkitProgress"
                            max="100"
                            variant="mhc-dark"
                            height=".5rem"
                            width="100%"
                            class="questionnaire-progress"
                        />
                        <div class="text-center mt-1">
                            <small>{{ worksheetIndex }}/{{ currentToolkit.worksheets.length }}</small>
                        </div>
                    </div>
                </b-row>

                <div class="p-2" v-if="currentToolkit.worksheets.length > 0">                    
                    <div class="text-center">
                        <h3>{{ currentToolkit.worksheets[worksheetIndex].text_1 }}</h3>
                        <div v-if="currentToolkit.worksheets[worksheetIndex].help">
                            <small class="cursor-pointer" @click="openHelpModal" v-b-tooltip.hover.bottom="'Click to see help'">Help</small>
                        </div>
                    </div>

                    <div v-if="currentToolkit.worksheets[worksheetIndex].type.name == 'Audio'">
                        <audio-worksheet :data="currentToolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.worksheets[worksheetIndex].type.name == 'Video'">
                        <video-worksheet :data="currentToolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.worksheets[worksheetIndex].type.name == 'Cue card'">
                        <cue-worksheet :data="currentToolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-else-if="currentToolkit.worksheets[worksheetIndex].type.name == 'Textbox'">
                        <textbox-worksheet :data="currentToolkit.worksheets[worksheetIndex]" />
                    </div>
                    <div v-if="currentToolkit.worksheets[worksheetIndex].type.name == 'Document'">
                        <document-worksheet :data="currentToolkit.worksheets[worksheetIndex]" />
                    </div>
                </div>

                <help-modal :title="currentToolkit.worksheets[worksheetIndex].text_1" :content="currentToolkit.worksheets[worksheetIndex].help"/>

                <div class="d-flex justify-content-between">
                    <b-button class="bg-mhc-dark" @click="prevWorksheet" v-if="worksheetIndex > 0">
                        Previous
                    </b-button>
                    <b-button class="bg-mhc-dark ml-auto" @click="nextWorksheet">
                        Next
                    </b-button>
                </div>
            </div>
            <div v-else>
                <div>
                    <toolkit-completed :title="currentToolkit.title"/>
                    <div class="d-flex ">
                        <b-button class="bg-mhc-dark ml-auto" @click="finishWorksheet">
                            Finish
                        </b-button>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import EventBus from "@/main.js"
import AudioWorksheet from '@/components/toolkit/worksheets/audio/AudioWorksheet.vue'
import DocumentWorksheet from '@/components/toolkit/worksheets/DocumentWorksheet.vue'
import VideoWorksheet from '@/components/toolkit/worksheets/video/VideoWorksheet.vue'
import CueWorksheet from '@/components/toolkit/worksheets/CueWorksheet.vue'
import TextboxWorksheet from '@/components/toolkit/worksheets/TextboxWorksheet.vue'
import ToolkitCompleted from '@/components/toolkit/worksheets/ToolkitCompleted.vue'
import HelpModal from "@/components/modals/toolkit/HelpModal.vue"
import ProfessionalProfile from "@/components/modals/ProfessionalProfile.vue"

import {
  BCard, BRow, BProgress, BButton, VBTooltip
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BRow,
        BProgress,
        BButton,
        AudioWorksheet,
        DocumentWorksheet,
        VideoWorksheet,
        CueWorksheet,
        TextboxWorksheet,
        ToolkitCompleted,
        HelpModal,
        ProfessionalProfile
    },
    directives: { 'b-tooltip': VBTooltip },
    computed: {
        currentToolkit() {
            return this.$store.getters["toolkitStoreModule/currentToolkit"]
        },
        toolkitProgress() {
            return this.$store.getters["toolkitStoreModule/toolkitProgress"]
        },
        worksheetIndex() {
            return this.$store.getters["toolkitStoreModule/worksheetIndex"]
        },
    },
    methods: {
        prevWorksheet() {
            // Increment worksheet index (open new worksheet)
            this.$store.dispatch("toolkitStoreModule/decrementWorksheetIndex");

            // Update toolkit progress
            this.$store.dispatch("toolkitStoreModule/setToolkitProgress", (this.worksheetIndex / this.currentToolkit.worksheets.length) * 100);
        },
        nextWorksheet() {
            // Increment worksheet index (open new worksheet)
            this.$store.dispatch("toolkitStoreModule/incrementWorksheetIndex");

            // Update toolkit progress
            this.$store.dispatch("toolkitStoreModule/setToolkitProgress", (this.worksheetIndex / this.currentToolkit.worksheets.length) * 100);
        },
        finishWorksheet() {
            // Reset worksheet index
            this.$store.dispatch("toolkitStoreModule/resetWorksheetIndex");
            
            // Redirect to all toolkits
            this.$router.push({name: 'learn-and-develop-edit', params: { id: this.$route.params.id }})
        },
        openHelpModal() {
            EventBus.$emit('openHelpToolkitModal')
        },
        openProfessionalProfileModal() {
          EventBus.$emit('openProfessionalProfileModal');
        },
    },
    mounted() {
        // Set current toolkit
        this.$store.dispatch("toolkitStoreModule/setCurrentToolkit", this.$route.params.id)
    }
}
</script>