<template>
    <div>
        <div class="text-center">
            <div class="mt-3" v-if="data.text_2">
                <p class="font-weight-normal">{{ data.text_2 }}</p>
            </div>
            <div class="mt-3">
                <audio-player :url="data.worksheet_content.media_url"/>
            </div>
            <div class="mt-3" v-if="data.worksheet_content.transcript">
                <p class="font-weight-normal">{{ data.worksheet_content.transcript }}</p>
            </div>
        </div>        
    </div>
</template>

<script>
import AudioPlayer from "@/components/toolkit/worksheets/audio/AudioPlayer.vue"

export default {
    components: {
        AudioPlayer,
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
}
</script>