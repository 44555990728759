<template>
    <div>
        <!-- Hide the default audio player -->
        <div>
            <audio class="d-none" ref="player" :key="audioKey">
                <source :src="url" type="audio/mpeg" />
            </audio>
        </div>
            
        <div class="audio-player m-auto border rounded p-1">
            <div class="d-flex w-100">
                <div class="pr-1">
                    <b-button
                      @click="toggleAudio()"
                      v-show="!isPlaying"
                      class="bg-mhc-dark btn-icon rounded-circle p-1"
                    >
                      <feather-icon icon="PlayIcon" size="24"/>
                    </b-button>

                    <b-button
                      @click="toggleAudio()"
                      v-show="isPlaying"
                      class="bg-mhc-dark btn-icon rounded-circle p-1"
                    >
                      <feather-icon icon="PauseIcon" size="24"/>
                    </b-button>
                </div>

                <div class="w-100">
                    <div
                        id="progress-bar"
                        class="w-100"
                    >
                        <div class="w-100">
                            <input
                                v-model="playbackTime"
                                type="range"
                                min="0"
                                :max="audioDuration"
                                id="position"
                                name="position"
                                style="height: 25px; border-radius: 50px;"
                                class="border"
                            />

                            <!-- Show loading indicator until audio has been loaded -->

                            <div v-show="!audioLoaded"
                            class="w-100">
                            Loading please wait...
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            v-show="audioLoaded"
                            class="d-flex w-100 justify-content-between"
                        >
                            <div class="text-sm" v-html="elapsedTime()"> 00:00 </div>
                            <div class="text-sm" v-html="totalTime()"> 00:00 </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
        <!-- outer gray border -->
    </div>
    <!-- root -->
</template>

<script>
import {
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BButton
  },
  props: {
      url: {
          type: String,
          required: true
      }
  },
  /**
   * playbackTime = local var that syncs to audio.currentTime
   * audioDuration = duration of audio file in seconds
   * isPlaying = boolean (true if audio is playing)
   *
   **/
  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      audioKey: 0,
    };
  },
  methods: {
    //Set the range slider max value equal to audio duration
    initSlider() {
      let audio = this.$refs.player;
      if (audio) {
      //   this.audioDuration = Math.round(audio.duration);
        audio.currentTime = 0;
        this.playbackTime = 0
        this.audioDuration = Math.round(this.$refs.player.duration);
      }
    },
    //Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      let hours = seconds / 3600;
      let minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    //Show the total duration of audio file
    totalTime() {
      let audio = this.$refs.player;
      if (audio) {
        let seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    //Display the audio time elapsed so far
    elapsedTime() {
      let audio = this.$refs.player
      if (audio) {
        // Update audio time
        audio.addEventListener("timeupdate", this.playbackListener);

        let seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }

    },
    //Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();

    },
    //Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();

    },
    //Playback listener function runs every 100ms while audio is playing
    playbackListener(e) {
      let audio = this.$refs.player;
      //Sync local 'playbackTime' let to audio.currentTime and update global state
      this.playbackTime = audio.currentTime;

      //Add listeners for audio pause and audio end events
      audio.addEventListener("ended", this.endListener);
      audio.addEventListener("pause", this.pauseListener);
    },
    //Remove listeners after audio play stops
    cleanupListeners() {
      let audio = this.$refs.player;
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("pause", this.pauseListener);

    },
    toggleAudio() {
      let audio = this.$refs.player;
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
  },
  watch: {
    $props: {
      handler(val) {
          this.audioKey++;

          this.$nextTick(function() {
            let audio = this.$refs.player;

            audio.addEventListener(
              "loadedmetadata",
              function (e) {
                this.initSlider();
              }.bind(this)
            );
          })

      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // nextTick code will run only after the entire view has been rendered
    this.$nextTick(function () {
      let audio = this.$refs.player;
      // Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      audio.addEventListener(
        "loadedmetadata",
        function (e) {
          this.initSlider();
        }.bind(this)
      );
      // "canplay" HTML Event lets us know audio is ready for play
      audio.addEventListener(
        "canplay",
        function (e) {
          this.audioLoaded = true;
        }.bind(this)
      );
      //Wait for audio to begin play, then start playback listener function
      this.$watch("isPlaying", function () {
        if (this.isPlaying) {
          let audio = this.$refs.player;
          this.initSlider();
          
          // prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true;
            // for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });
      // Update current audio position when user drags progress slider
      this.$watch("playbackTime", function () {
        let diff = Math.abs(this.playbackTime - this.$refs.player.currentTime);

        // Check is current time is equal duration time (is it over)
        // if (this.$refs.player.currentTime == this.$refs.player.duration) {
        //   this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
        // }

        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.$refs.player.currentTime = this.playbackTime;
        }
      });
    });
  },
};
</script>

<style>
.audio-player {
  max-width: 600px
}

input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 0; /* iOS */
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

::-webkit-slider-runnable-track {
  background: #fff;
}

/* 1. Set to 0 width and remove border for a slider without a thumb */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0; /* 1 */
  height: 40px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw var(--mhc-dark);
  border: none; /* 2px solid #999; */
}

::-moz-range-track {
  height: 40px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 0; /* 20px; */
  border: none; /* 3px solid #999; */
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw var(--mhc-dark);
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: var(--mhc-dark);
}

::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

::-ms-tooltip {
  display: none;
}
</style>