<template>
    <div>
        <div class="text-center">
            <div class="mt-3" v-if="data.text_2">
                <p class="font-weight-normal">{{ data.text_2 }}</p>
            </div>
            <div class="mt-2">
                <div v-if="data.worksheet_content.video_type == 'file'">
                    <video-player :url="data.worksheet_content.media_url"/>
                </div>
                <div v-else>
                    <div v-if="data.worksheet_content.video_type == 'youtube'">
                        <youtube :video-id="youtubeVideoId" ref="youtube" @ended="videoEnded" class="video-player" />
                    </div>
                    <div v-if="data.worksheet_content.video_type == 'vimeo'">
                        <vimeo-player ref="vimeo" :video-id="vimeoVideoId" @ready="onReady" @ended="videoEnded" :player-height="height" class="video-player" />
                    </div>
                    <div v-else>
                    </div>
                </div>
            </div>
            <div class="mt-2" v-if="data.worksheet_content.transcript">
                <p class="font-weight-normal">{{ data.worksheet_content.transcript }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import VideoPlayer from "@/components/toolkit/worksheets/video/VideoPlayer.vue"

export default {
    components: {
        VideoPlayer,
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            youtubeVideoId: '',
            vimeoVideoId: '',
            height: 360,
            options: {},
            playerReady: false,
        }
    },
    methods: {
        videoEnded() {
            // Set next button disable to false
            this.$store.dispatch("toolkitStoreModule/changeNextBtnStatus", false);
        },
        onReady() {
            this.playerReady = true
        },
    },
    computed: {
        player() {
            return this.$refs.youtube.player
        }
    },
    watch: {
        $props: {
            handler(val) {
                if(val.data.worksheet_content.video_link != undefined) {
                    if(val.data.worksheet_content.video_link.includes('yout')) {
                        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                        var match = val.data.worksheet_content.video_link.match(regExp);
                        if (match&&match[7].length==11) {
                            this.youtubeVideoId = match[7]
                        }
                    } else {
                        let mediaVideoLink = val.data.worksheet_content.video_link
                        let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
                        let match = mediaVideoLink.match(regEx);
                        if (match && match.length == 7) {
                            let videoId = match[6];
                            this.vimeoVideoId = videoId
                        } else {
                            //Handle error
                            console.log('Error with URL')
                        }
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
