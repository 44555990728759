<template>
  <div>
    <div class="text-center">
      <div class="w-80 mt-5 mx-auto">
        <b-form-textarea v-model="content" placeholder="Your answer" rows="10" />
        <div class="mt-1">
          <small>This field is required for users</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    BFormTextarea,
  },
  props: {
      data: {
          type: Object,
          required: true
      }
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    setResponse() {
      this.$store.dispatch(
        "toolkitStoreModule/setCurrentWorksheetResponse",
        this.content
      );
    },
  },
};
</script>

<style scoped>
.w-80 {
  max-width: 80%;
}
</style>
